export const introSectionContent = {
  title: {
    pl: 'Ochrona Prywatności',
    en: 'Privacy Protection',
  },
  back: {
    pl: 'CARGONITE',
    en: 'CARGONITE',
  },
  desc: {
    pl: '',
    en: '',
  },
}

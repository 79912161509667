import React from 'react'
import { Link } from 'gatsby'
import { useLangContext } from '../../../context/lang.context'
import { sContent } from '../../../style/style'
import { colors } from '../../../style'

const PrivacyContent = () => {
  const { lang } = useLangContext()

  if (lang === 'en')
    return (
      <div css={[sContent, sContent2]}>
        <h2>Data Protection and Privacy Policy</h2>
        <p>
          Cargonite Spółka z o.o. (hereinafter: Cargonite) respects the right to the
          protection of personal data and the right to privacy of its clients,
          employees, contractors and business partners. Therefore, the company
          has appointed a data protection officer. It is a person with whom you
          can contact in all matters relating to the processing of personal data
          and the exercise of rights related to data processing.
        </p>
        <p>
          <ul>
            The inspector can be contacted in the following way:
            <li>
              by letter to the following address: Tęczowa 13, 53-601 Wrocław
            </li>
            <li>
              by e-mail <a href='mailto:rodo@cargonite.pl'>rodo@cargonite.pl</a>
            </li>
          </ul>
        </p>
        <p>
          Cargonite's personal data protection policy in the scope of international
          and domestic forwarding, logistics, transport, customs agencies and
          other services related to the handling of trade in goods -
          downloadable document:
        </p>
        <p>
          <strong>
            &nbsp;
            <Link to='/pdf/podo-en.pdf'>
              Personal Data Protection Policy PDF
            </Link>
          </strong>
        </p>

        <p>
          The services provided by Cargonite Spółka z o.o., Tęczowa 13, 53-601 Wrocław (hereinafter: Cargonite) and entities related to Cargonite,
          include the processing of personal data of data subjects on behalf of
          the Client / Contractor. With regard to such personal data, Cargonite will
          act as a Processor and the Client / Contractor will act as the
          Administrator. This type of processing is subject to the
          above-mentioned and published Policy.
        </p>
        <p>
          To find out how Cargonite Spółka z o.o. processes your personal data, we
          encourage you to read the following information clauses regarding:
        </p>
        <ul>
          <li>
            <Link
              to='/pdf/recruitment-en.pdf'
              target='_blank'
              rel='noreferrer noopener'>
              <strong>Recruitment process in our company</strong>
            </Link>
          </li>
          <li>
            <Link
              to='/pdf/employment-en.pdf'
              target='_blank'
              rel='noreferrer noopener'>
              <strong>
                The hiring process and data protection of our employees
              </strong>
            </Link>
          </li>
          <li>
            <Link
              to='/pdf/contracts-en.pdf'
              target='_blank'
              rel='noreferrer noopener'>
              <strong>Concluding contracts</strong>
            </Link>
          </li>
          <li>
            <Link
              to='/pdf/contact-forms-en.pdf'
              target='_blank'
              rel='noreferrer noopener'>
              <strong>Use of our contact forms</strong>
            </Link>
          </li>
        </ul>
        <p>
          Additionally, we would like to inform you that in order to exercise
          your rights related to data processing, you can contact our data
          inspector directly or use the following forms:
        </p>
        <ul>
          <li>
            <Link
              to='/pdf/app-privacy-en.pdf'
              target='_blank'
              rel='noreferrer noopener'>
              <strong>
                Application form for the exercise of data protection rights
              </strong>
            </Link>
          </li>
          <li>
            <Link
              to='/pdf/app-share-data-en.pdf'
              target='_blank'
              rel='noreferrer noopener'>
              <strong>
                Application form for disclosure of personal data held by Cargonite Spółka z o.o.
              </strong>
            </Link>
          </li>
        </ul>
      </div>
    )

  return (
    <div css={[sContent, sContent2]}>
      <h2>Ochrona Danych i Politka Prywatności</h2>
      <p>
        Cargonite Spółka z ograniczoną odpowiedzialnością (dalej: Cargonite)
        szanuje prawo do ochrony danych osobowych oraz prawo do prywatności
        swoich klientów, pracowników, kontrahentów i partnerów biznesowych. W
        związku z powyższym spółka wyznaczyła inspektora ochrony danych. Jest to
        osoba, z którą mogą się Państwo kontaktować we wszystkich sprawach
        dotyczących przetwarzania danych osobowych oraz korzystania z praw
        związanych z przetwarzaniem danych.
      </p>
      <p>
        <ul>
          Z inspektorem można się kontaktować w następujący sposób:
          <li>listownie na adres: Tęczowa 13, 53-601 Wrocław</li>
          <li>
            przez e-mail{' '}
            <a href='mailto:rodo@cargonite.pl'>rodo@cargonite.pl</a>
          </li>
        </ul>
      </p>

      <p>
        Usługi świadczone przez Cargonite i podmioty powiązane obejmują
        przetwarzanie danych osobowych osób, których dane dotyczą, w imieniu
        Klienta/Kontrahenta. W odniesieniu do takich danych osobowych Cargonite
        będzie pełnić funkcję Podmiotu przetwarzającego, a Klient/Kontrahent
        będzie pełnić funkcję Administratora. Tego typu przetwarzanie jest
        przedmiotem Polityki ochrony danych osobowych spółki Cargonite w
        zakresie międzynarodowych i krajowych usług spedycyjnych, logistycznych,
        przewozowych, agencji celnych oraz innych związanych z obsługą obrotu
        towarowego – dokument do pobrania:
      </p>
      <p>
        <strong>
          &nbsp;
          <Link to='/pdf/podo-pl.pdf' target='_blank' rel='noreferrer noopener'>
            Polityka Ochrony Danych Osobowych PDF
          </Link>
        </strong>
      </p>
      <p>
        Aby dowiedzieć się w jaki sposób Cargonite przetwarza Państwa dane
        osobowe, zachęcamy do zapoznania się z poniższymi klauzulami
        informacyjnymi, które dotyczą:
      </p>
      <ul>
        <li>
          <Link
            to='/pdf/recruitment-pl.pdf'
            target='_blank'
            rel='noreferrer noopener'>
            <strong>Proces rekrutacji w naszej firmie</strong>
          </Link>
        </li>
        <li>
          <Link
            to='/pdf/employment-pl.pdf'
            target='_blank'
            rel='noreferrer noopener'>
            <strong>
              Procesu zatrudniania i ochrony danych naszych pracowników
            </strong>
          </Link>
        </li>
        <li>
          <Link
            to='/pdf/contracts-pl.pdf'
            target='_blank'
            rel='noreferrer noopener'>
            <strong>Zawieranie umów</strong>
          </Link>
        </li>
        <li>
          <Link
            to='/pdf/contact-forms-pl.pdf'
            target='_blank'
            rel='noreferrer noopener'>
            <strong>Korzystania z naszych formularzy kontaktowy</strong>
          </Link>
        </li>
      </ul>
      <p>
        Dodatkowo informujemy, że aby skorzystać z praw związanych z
        przetwarzaniem danych mogą się Państwo bezpośrednio skontaktować z
        naszym inspektorem danych lub skorzystać z poniższych formularzy:
      </p>
      <ul>
        <li>
          <Link
            to='/pdf/app-privacy-pl.pdf'
            target='_blank'
            rel='noreferrer noopener'>
            <strong>Wniosek o realizację praw z zakresu ochrony danych</strong>
          </Link>
        </li>
        <li>
          <Link
            to='/pdf/app-share-data-pl.pdf'
            target='_blank'
            rel='noreferrer noopener'>
            <strong>Wniosek o udostępnienie danych</strong>
          </Link>
        </li>
      </ul>
    </div>
  )
}

const sContent2 = {
  ul: { li: { margin: '1rem 1rem' } },
  a: { color: colors.main },
}

export default PrivacyContent

import React from 'react'
import Seo from '../../components/Seo'
import { useLangContext } from '../../context/lang.context'
import IntroSection from './IntroSection/IntroSection'
import { seo } from './seo'
import PrivacyContent from './PrivacyContent/PrivacyContent'

const Services = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo
        title={seo.title[lang]}
        description={seo.description[lang]}
        lang={lang}
      />
      <IntroSection />
      <PrivacyContent />
    </>
  )
}

export default Services

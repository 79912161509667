export const seo = {
  title: {
    pl: 'Prywatność',
    en: 'Privacy',
  },
  description: {
    pl: '',
    en: '',
  },
}
